import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layout/DefaultLayout.vue'),
    children:[
      {
        path: '',
        component: () => import('@/views/HomeView.vue'),
        meta:{
          title: 'Home'
        }
      }
    ],
  },
  {
    path: "/privacy",
    component: () => import('@/layout/DefaultLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/PrivacyView.vue'),
        meta: {
          title: "Gestione Privacy"
        }
      }
    ]
  },
  {
    path: "/payments",
    component: () => import('@/layout/DefaultLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/PaymentView.vue'),
        meta: {
          title: "Gestione Pagamenti"
        }
      }
    ]
  },
  {
    path: '/account',
    component: () => import('@/layout/DefaultLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/AccountView.vue'),
        meta: {
          title: 'Il tuo account'
        }
      }
    ]
  },
  {
    path: '/oauth',
    component: () => import('@/layout/EmptyLayout.vue'),
    children: [
      {
        path: 'authorize/:app',
        component: () => import('@/views/AuthorizeView.vue'),
        meta: {
          title: "Richiesta autorizzazione"
        }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: 'Accedi'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {
  document.title = to.meta.title;
  next();
});

export default router
