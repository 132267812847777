import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import router from './router'
import {StripePlugin} from "@vue-stripe/vue-stripe";

Vue.config.productionTip = false
Vue.use(VueTelInputVuetify, {
    vuetify
})

Vue.use(StripePlugin, {
    pk: 'pk_test_ZD6fvQyhf8eSk9EzPUonqMfl00bIiXuw2e',
    stripeAccount: 'acct_1FDBTwAqU7pVwA33',
    apiVersion: '2024-04-10',
    locale: 'IT'
});

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
