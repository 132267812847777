<template>
  <v-app>
    <v-main>
      <router-view v-if="!loading"></router-view>
      <v-container v-else style="height: 100vh">
        <v-row style="height: 100%" align="center" justify="center">
          <v-col cols="6" class="mx-auto text-center">

            <img src="@/assets/logo.png" style="height: 140px" alt="logo"/>
            <br/>
            <br/>
            <v-progress-circular indeterminate color="black" size="50"></v-progress-circular>
            <br/>
            <br/>
            <p>Carico...</p>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog persistent v-model="error.show" max-width="500" style="z-index: 9999999999; position: absolute">
        <v-card>
          <v-card-title>{{ error.title }}</v-card-title>
          <v-card-text>{{ error.message }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="action.action" :color="action.color ?? 'primary'" :key="index"
                   v-for="(action, index) in error.actions">{{ action.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snack.show" timeout="1000">{{ snack.message }}</v-snackbar>
      <v-dialog max-width="500" persistent v-model="showEmailDialog">
        <v-card :loading="emailDialogLoading">
          <v-card-title>Aggiungi la tua email</v-card-title>
          <v-container>
            <p>Ti chiediamo la tua email per aiutarti a recuperare il tuo account in casi di emergenza. <br/>Non
              utilizzeremo le tue informazioni per scopi terzi senza la tua autorizzazione esplicita.</p>
            <v-form @submit="updateUserEmail" ref="emailForm">
              <v-text-field v-model="emailValue"
                            :rules="[v => !!v || 'L\'email è obbligatoria', v => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 'La mail non è valida']"
                            filled label="Email" type="email"></v-text-field>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateUserEmail" text color="black">Conferma</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog max-width="500" persistent v-model="showMarketingConsentDialog">
        <v-card :loading="marketingDialogLoading">
          <v-card-title>Un'ultima cosa</v-card-title>
          <v-card-subtitle>Parliamo del tuo consenso marketing</v-card-subtitle>
          <v-container>
            <p>
              Qui su On My World, ci impegniamo costantemente a migliorare la tua esperienza offrendoti servizi più personalizzati. A tale scopo, desideriamo chiederti se sei disposto a condividere i tuoi dati con terze parti. Questo ci consentirà di offrirti raccomandazioni più pertinenti e servizi su misura per te. Vogliamo rassicurarti che trattiamo i tuoi dati con la massima riservatezza e sicurezza.
              <br/>
              Per saperne di più su come gestiamo i tuoi dati e sui tuoi diritti, ti invitiamo a leggere la nostra Privacy Policy, disponibile all'indirizzo https://onmy.world/about/privacy.
              <br/>
              Inoltre, ti ricordiamo che puoi gestire i tuoi dati nella sezione home di questa piattaforma. Se desideri revocare il consenso in qualsiasi momento, puoi farlo semplicemente togliendo la spunta nella sezione 'Privacy', sotto la voce 'Consenso marketing'.
              <br/>
              Ti invitiamo cortesemente a cliccare sul pulsante 'Condividi' per fornire il tuo consenso. Grazie per la tua collaborazione e per essere parte della nostra comunità On My World!
            </p>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="setMarketingConsent('reject')" text color="red">Nego</v-btn>
            <v-btn @click="setMarketingConsent('accept')" color="black" depressed style="color: white">Condividi</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>
<script>
import Vue from "vue";
import {Cookies} from "@/core/Cookie";
import store from "@/store";
import {axios, toFormData} from '@/core/ApiService';

export default {
  name: 'main-app',

  async mounted() {
    await this.loadUser();
    this.$nextTick(() => {
      Vue.prototype.$showDialog = this.showDialog;
      Vue.prototype.$closeDialog = this.closeDialog;
      Vue.prototype.$showSnackbar = this.showSnackbar;
    });
  },

  data() {
    return {
      error: {
        title: 'Attenzione',
        message: '',
        show: false,
        actions: [],
      },
      snack: {
        show: false,
        message: ''
      },
      loading: true,
      showEmailDialog: false,
      emailDialogLoading: false,
      emailValue: '',
      showMarketingConsentDialog: false,
      marketingDialogLoading: false
    }
  },
  methods: {
    showDialog(obj) {
      this.error.title = obj.title ?? "Attenzione";
      this.error.message = obj.message;
      this.error.actions = obj.actions ?? [
        {
          text: 'Ok',
          action: () => this.closeDialog()
        }
      ];
      this.error.show = true;
    },

    closeDialog() {
      this.error.show = false;
    },
    showSnackbar(message) {
      this.snack = {
        show: true,
        message: message
      }
    },
    async loadUser() {
      if (!Cookies.isKey('auth')) {
        this.loading = false;
        await this.$router.push('/login');
        return;
      }
      if (!store.state.user) {
        let res = await axios.get('/user/me');
        if (!res.data.ok) {
          Cookies.remove('auth');
          this.loading = false;
          await this.$router.push('/login');
          return;
        }
        store.commit('setUser', res.data.user);
        this.loading = false;
        this.showEmailDialog = res.data.user.email === null
        if (this.showEmailDialog) return;
        this.showMarketingConsentDialog = res.data.user.marketing_consent === null;
      }
    },

    async updateUserEmail() {
      if (!this.$refs.emailForm.validate()) {
        return;
      }
      try {
        this.emailDialogLoading = true;
        let res = await axios.post('/user/update/email', toFormData({
          email: this.emailValue
        }));
        if (res.data.ok) {
          await this.loadUser();
          this.showEmailDialog = false;
          window.location.reload();
          return;
        }
        this.showDialog({
          title: 'Attenzione',
          message: res.data.message
        });
      } catch (e) {
        this.$showDialog({
          title: 'Attenzione',
          message: 'Si è verificato un errore interno',
          actions: [
            {
              title: 'Ricarica la pagina',
              action: () => window.location.reload()
            }
          ]
        })
      } finally {
        this.emailDialogLoading = false;
      }
    },
    async setMarketingConsent(status){
      this.marketingDialogLoading = true;
      try{
        let res = await axios.post('/user/update/marketing',toFormData({
          consent: status
        }));
        if(!res.data.ok){
          this.showDialog({
            title: 'Attenzione',
            message: res.data.message,
            actions:[
              {
                text: 'Riprova',
                action: () => window.location.reload()
              }
            ]
          });
          return;
        }
        this.showMarketingConsentDialog = false;
        window.location.reload();
        await this.loadUser();
      }catch (e) {
        this.showDialog({
          title: 'Attenzione',
          message: "Si è verificato un errore interno. Riprova più tardi",
          actions:[
            {
              text: 'Ok',
              action: () => window.location.reload()
            }
          ]
        });
      }finally {
        this.marketingDialogLoading = false;
      }
    }
  }
}
</script>