import axios from 'axios';
import {Cookies} from "@/core/Cookie";

axios.defaults.baseURL = "https://api.onmy.world";

if(Cookies.isKey('auth')){
    axios.defaults.headers = {
      'Authorization': 'Bearer '+Cookies.get('auth')
    };
}

function toFormData(obj){
    const formData = new FormData();
    const keys = Object.keys(obj);
    keys.forEach(el => {
       formData.append(el, obj[el]);
    });

    return formData;
}

export {
    axios,
    toFormData
}