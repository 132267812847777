class Cookies {
    static get(name) {
        const cookieName = `${name}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (let cookie of cookieArray) {
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return null;
    }

    static isKey(name) {
        return this.get(name) !== null;
    }

    static remove(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

  static set(name, value, expiration) {
    let expires = '';
    if (expiration) {
      const date = new Date();
      switch (expiration.slice(-1)) {
        case 'h':
          date.setTime(date.getTime() + parseInt(expiration) * 60 * 60 * 1000);
          break;
        case 'd':
          date.setTime(date.getTime() + parseInt(expiration) * 24 * 60 * 60 * 1000);
          break;
        case 'm':
          date.setMonth(date.getMonth() + parseInt(expiration));
          break;
        case 'y':
          date.setFullYear(date.getFullYear() + parseInt(expiration));
          break;
        default:
          console.log('Invalid expiration format. Please use "h" for hours, "d" for days, "m" for months, or "y" for years.');
          return;
      }
      expires = `;expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires};path=/;`;
  }
}

export {Cookies}
